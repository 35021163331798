import React, {useEffect, useState} from 'react';
import './changepricelike.scss';

import Sidebar from '../../Components/Sidebar/Sidebar';
import Navbar from '../../Components/Navbar/Navbar';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Button, TextField} from "@mui/material";
import {createNewPriceEntry, updateExistingLikePriceEntry, getAllLikePrice} from "../../services/likeService";

const ChangePriceLike = () => {
    const [level, setLevel] = useState('1');
    const [type, setType] = useState('CollectionItem');
    const [price, setPrice] = useState('');
    const [status, setStatus] = useState('default');
    const [existingData, setExistingData] = useState([]);

    useEffect(() => {
        getAllLikePrice().then(response => {
            setExistingData(response.data);
        });
    }, [status]);

    useEffect(() => {
        const existingItem = existingData.find(item => item.userLevel === parseInt(level) && item.itemType === type);
        if (existingItem) {
            setPrice(existingItem.price.toString());
        } else {
            setPrice('');
        }
    }, [level, type, existingData]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                changePriceForLike();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [level, type, price]);

    const handleChangePrice = (event) => {
        setLevel(event.target.value);
    };

    const handleChangeType = (event) => {
        setType(event.target.value);
    };

    const handleTextChange = (event) => {
        setPrice(event.target.value);
    };

    const changePriceForLike = () => {
        const existingItem = existingData.find(item => item.userLevel === parseInt(level) && item.itemType === type);

        const data = {
            "itemType": type,
            "price": +price,
            "userLevel": +level,
            "itemLevel": +level
        };

        if (existingItem) {
            updateExistingLikePriceEntry(data,existingItem._id)
                .then((data) => {
                    if (!data.error) {
                        setStatus('success');
                    } else {
                        setStatus('error');
                    }
                })
                .catch(() => {
                    setStatus('error');
                });
        } else {
            createNewPriceEntry(data)
                .then((data) => {
                    if (!data.error) {
                        setStatus('success');
                    } else {
                        setStatus('error');
                    }
                })
                .catch(() => {
                    updateExistingLikePriceEntry(data)
                        .then((data) => {
                            if (!data.error) {
                                setStatus('success');
                            } else {
                                setStatus('error');
                            }
                        })
                        .catch(() => {
                            setStatus('error');
                        });
                });
        }
    };


    const clearAll = () => {
        setLevel('1');
        setType('CollectionItem');
        setPrice('');
        setStatus('default');
    };

    return (
        <div className='changePriceBar'>
            <Sidebar/>
            <div className="changePriceContainer">
                <Navbar/>
                <div className="changePriceLike">
                    <div className="changePriceLike_select">
                        <Box sx={{width: 300}}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select level</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={level}
                                    label="Level"
                                    onChange={handleChangePrice}
                                >
                                    {[...Array(12).keys()].map((num) => (
                                        <MenuItem key={num + 1} value={num + 1}>
                                            {num + 1}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className="changePriceLike_select">
                        <Box sx={{width: 300}}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={type}
                                    label="Type"
                                    onChange={handleChangeType}
                                >
                                    <MenuItem value={'NFTCollection'}>
                                        NFT Collection
                                    </MenuItem>
                                    <MenuItem value={'NFTItem'}>
                                        NFT Item
                                    </MenuItem>
                                    <MenuItem value={'CollectionItem'}>
                                        Collection item
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className="changePriceLike_input">
                        <TextField
                            id="outlined-basic"
                            label="Price"
                            variant="outlined"
                            value={price}
                            onChange={handleTextChange}
                        />
                    </div>
                    <div className="changePriceLike_input_button" >
                        {
                            status === 'default' ?
                                <Button onClick={() => {
                                    changePriceForLike()
                                }} variant="contained">Change Price</Button>
                                : status === 'success' ?
                                    <Button onClick={() => {
                                        clearAll()
                                    }} variant="contained" color="success">
                                        Success
                                    </Button>
                                    :
                                    <Button onClick={() => {
                                        clearAll()
                                    }} variant="outlined" color="error">
                                        Error
                                    </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePriceLike;
